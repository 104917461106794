import React from 'react'
import { Review as ReviewInf } from 'src/packages/domain'
import { Image } from '../index'

const Review = (props: ReviewInf) => {

    return <div 
        className="px-5 sm:p-3 md:p-4 xl:p-8 text-center lg:text-right"
    >

        <div className="font-medium">
            {props?.excerpt && 
                <div dangerouslySetInnerHTML={{__html: props.excerpt}} />
            }
            <p className="font-light mb-4">{props?.author}</p>

        </div>

        <div className="flex justify-center lg:justify-start">
            {props.thumbnail &&
                // eslint-disable-next-line jsx-a11y/alt-text
                <Image {...props.thumbnail} width={"379"} height={"220"} />
            }

        </div>        
    </div>  
}

export default Review;