import React from 'react'
import Image from 'next/image'

import { Image as ImageInf } from 'src/packages/domain'

type ImagePropType = ImageInf

const FinalImage = ({src,alt,width,height}: ImagePropType) => {

    if (width && height) {
        return <Image {...{src,width,height}} alt={alt || "deco"}  />
    }

    const layout = "fill";

    return <Image {...{src,layout}} alt={alt || "deco"}  />

}

export default FinalImage
export type {ImagePropType}