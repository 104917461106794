import { Review as ReviewItem } from '..'
import { Review } from 'src/packages/domain'


type Props = {
    reviews?: Review[]
}

const ReviewFlex = ({reviews}: Props) => {
    return (
        <>
            { reviews && reviews.map((review,i) =>
                <div className="rtl sm:pb-10 md:pb-20" key={i}>
                    <ReviewItem  {...review} />
                </div>
            )}
        </>        
    )
}

export default ReviewFlex